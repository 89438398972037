<template>
  <div class="auth-wrapper auth-v2">
    <div class="auth-inner">
      <!-- brand logo -->
      <router-link
        to="/"
        class="brand-logo d-flex align-center"
      >
        <v-img
          :src="appLogo"
          max-height="30px"
          max-width="30px"
          alt="logo"
          contain
          class="me-3 "
        ></v-img>

        <h2 class="text--primary">
          {{ appName }}
        </h2>
      </router-link>
      <!--/ brand logo -->

      <v-row class="auth-row ma-0">
        <v-col
          lg="8"
          class="d-none d-lg-block position-relative overflow-hidden pa-0"
        >
          <div class="auth-illustrator-wrapper">
            <!-- triangle bg -->
            <img
              height="362"
              class="auth-mask-bg"
              :src="require(`@/assets/images/misc/mask-v2-light.png`)"
            />

            <!-- tree -->
            <v-img
              height="226"
              width="300"
              class="auth-tree"
              src="@/assets/images/misc/tree-4.png"
            ></v-img>

            <!-- 3d character -->
            <div class="d-flex align-center h-full pa-16 pe-0">
              <v-img
                contain
                max-width="100%"
                height="692"
                class="auth-3d-group"
                :src="require(`@/assets/images/3d-characters/group-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)"
              />
            </div>
          </div>
        </v-col>

        <v-col
          lg="4"
          class="d-flex align-center auth-bg pa-10 pb-0"
        >
          <v-row>
            <v-col
              cols="12"
              sm="8"
              md="6"
              lg="12"
              class="mx-auto"
            >
              <v-card flat>
                <v-card-text>
                  <p class="text-2xl font-weight-semibold text--primary mb-2">
                    欢迎来到{{ appName }}! 👋🏻
                  </p>
                  <p class="mb-2">
                    登录账号并开始管理{{ appName }}
                  </p>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import { required, emailValidator } from '@core/utils/validation'
import { useRouter } from '@core/utils'
import themeConfig from '@themeConfig'
import useJwt from '@/auth/jwt/useJwt'
import { useStore } from '@/hooks/useStore'

export default {
  setup() {
    const { router } = useRouter()
    const { getUserInfo } = useStore('user')
    const backUrl = router.currentRoute.query.back ? router.currentRoute.query.back : `${window.location.origin}`
    const ticketStr = router.currentRoute.query.ticket

    function init() {
      localStorage.removeItem('accessToken')
      localStorage.removeItem('userData')
      localStorage.removeItem('vuex')
    }

    init()
    const goSsoAuthUrl = () => {
      useJwt.ssoAuthUrl({ clientLoginUrl: window.location.href, backUrl })
        .then(response => {
          window.location.href = response.data.data
        })
    }
    const doLoginByTicket = item => {
      useJwt.login(`ticket=${item}`)
        .then(response => {
          localStorage.setItem('accessToken', response.data.data)

          return response
        })
        .then(() => {
          getUserInfo().then(() => {
            router.replace('/')
          })
        })
    }

    if (ticketStr) {
      doLoginByTicket(ticketStr)
    } else {
      goSsoAuthUrl()
    }

    return {
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
      validators: {
        required,
        emailValidator,
      },

      // themeConfig
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,

      // Template Refs
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/pages/auth.scss';
</style>
